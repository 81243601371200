<template>
  <div>
    <DisclaimerModal
      @confirm="generatePDFs"
      v-if="type === 'link'"
    />

    <DisclaimerModal
      @confirm="generatePDFs"
      v-if="type === 'button'"
    >
      <template #activator="{ on, attrs }">
        <AdsButton
          v-bind="attrs"
          v-on="on"
          :secondary="true"
          style="border: 2px solid #041e42; color: #041e42;"
        >
          <v-icon>mdi-printer-outline</v-icon>
          &nbsp;
          Print student attendance list
        </AdsButton>
      </template>
    </DisclaimerModal>
  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';
import DisclaimerModal from '@/components/DisclaimerModal';
import { AdsButton } from '@nswdoe/doe-ui-core';

export default {
  name: 'AttendancePDF',
  props: {
    event: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: {},
    };
  },
  components: {
    DisclaimerModal,
    AdsButton
  },
  methods: {
    async getParticipants(eventId) {
      const response = await this.$api.registrants.getExistingRegistrations(
        eventId
      );

      // First map the registrants to the desired format
      const formattedStudents = response.registrants.map((participant) => ({
        fullName: `${participant.familyName}, ${participant.givenName}`,
        // Store original names for sorting
        familyName: participant.familyName,
        givenName: participant.givenName,
        srn: participant.studentRegistrationNumber,
        year: `Year ${participant.scholasticYearCode}`,
        schoolId: participant.schoolId,
        schoolName: participant.schoolName,
      }));

      // Sort students by family name, then given name
      const sortedStudents = formattedStudents.sort((a, b) => {
        // First compare by family name
        const familyNameCompare = a.familyName.localeCompare(b.familyName, 'en', { sensitivity: 'base' });

        // If family names are the same, compare by given name
        if (familyNameCompare === 0) {
          return a.givenName.localeCompare(b.givenName, 'en', { sensitivity: 'base' });
        }

        return familyNameCompare;
      });

      // Remove the temporary sorting fields before returning
      const cleanedStudents = sortedStudents.map(student => ({
        fullName: student.fullName,
        srn: student.srn,
        year: student.year,
        schoolId: student.schoolId,
        schoolName: student.schoolName
      }));

      return {
        eventName: this.event.title,
        startDate: this.event.start_date,
        endDate: this.event.end_date,
        category: this.event.category,
        vaCombined: this.event.combined_event,
        students: cleanedStudents,
      };
    },
    getBase64Image(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL('image/png');
          resolve(dataURL);
        };
        img.onerror = (error) => reject(error);
        img.src = url;
      });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Custom format for "Fri, 1 Nov 2024"
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const dayName = days[date.getDay()];
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${dayName}, ${day} ${month} ${year}`;
    },
    groupStudentsBySchool(students) {
      return students.reduce((acc, student) => {
        if (!acc[student.schoolId]) {
          acc[student.schoolId] = {
            schoolName: student.schoolName,
            students: [],
          };
        }
        acc[student.schoolId].students.push(student);
        return acc;
      }, {});
    },
    async createSchoolPDFDefinition(schoolData, logoData, eventDetails) {
      const headerContent = () => ({
        stack: [
          // Background rectangle
          {
            canvas: [
              {
                type: 'rect',
                x: 0,
                y: 0,
                w: 595.28,
                h: 160,
                color: '#CBEDFD',
              },
            ],
            absolutePosition: { x: 0, y: 0 },
          },
          // Header content
          {
            margin: [40, 40, 40, 0],
            columns: [
              {
                width: '*',
                stack: [
                  {
                    text: 'Education Pathways Program',
                    style: 'header',
                    margin: [0, 0, 0, 15],
                  },
                  {
                    text: schoolData.schoolName,
                    style: 'schoolName',
                    margin: [0, 0, 0, 10],
                  },
                  {
                    text: eventDetails.eventName,
                    style: 'eventName',
                    margin: [0, 0, 0, 15],
                  },
                ],
              },
              {
                width: 'auto',
                image: logoData,
                fit: [60, 60],
                margin: [20, 0, 0, 0],
              },
            ],
          },
          // Event details section
          {
            margin: [40, 30, 40, 0],
            columns: [
              {
                width: '40%',
                stack: [
                  {
                    columns: [
                      { text: 'Start date:', width: 70, bold: true },
                      { text: this.formatDate(eventDetails.startDate) },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                  {
                    columns: [
                      { text: 'End date:', width: 70, bold: true },
                      { text: this.formatDate(eventDetails.endDate) },
                    ],
                  },
                ],
              },
              {
                width: '60%',
                stack: [
                  {
                    columns: [
                      { text: 'VA Combined:', width: 100, bold: true },
                      { text: eventDetails.vaCombined ? 'Yes' : 'No' },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                  {
                    columns: [
                      { text: 'Category:', width: 100, bold: true },
                      { text: eventDetails.category },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      });

      // Create table rows without any extra spacing
      const tableRows = [
        // Header row
        [
          { text: 'Full name', style: 'tableHeader' },
          { text: 'SRN', style: 'tableHeader' },
          { text: 'Year', style: 'tableHeader' },
          { text: 'Attendance', style: 'tableHeader' },
        ],
        // Student rows - map directly without any additional spacing or empty rows
        ...schoolData.students.map(student => ([
          { text: student.fullName },
          { text: student.srn },
          { text: student.year },
          { text: '' }
        ]))
      ];

      return {
        pageSize: 'A4',
        pageMargins: [40, 240, 40, 120],
        header: headerContent,
        footer: () => ({
          margin: [40, 20, 40, 40],
          stack: [
            {
              text: [
                { text: 'Data privacy disclaimer: ', bold: true },
                'This document must be printed only if the user does not have internet access in the school to mark attendance on the EPP initiative management application. Please ensure that only the minimum required number of copies are made, the printed copy is not provided to third parties, and any printed pages are not left unattended. Once the attendance information has been entered into the application, the physical copy must be destroyed.',
              ],
              style: 'disclaimer',
            },
            {
              text: [
                'If you have any questions, please email the program team: ',
                {
                  text: 'Educational.Pathways@det.nsw.edu.au',
                  color: 'blue',
                  decoration: 'underline',
                },
              ],
              style: 'disclaimer',
              margin: [0, 10, 0, 0],
            },
          ],
        }),
        content: [
          {
            table: {
              headerRows: 1,
              widths: ['*', '*', '*', 75],
              body: tableRows
            },
            layout: {
              hLineWidth: function () {
                return 0.5;
              },
              vLineWidth: function () {
                return 0.5;
              },
              hLineColor: function () {
                return '#aaa';
              },
              vLineColor: function () {
                return '#aaa';
              },
              paddingLeft: function () { return 5; },
              paddingRight: function () { return 5; },
              paddingTop: function () { return 8; },
              paddingBottom: function () { return 8; }
            }
          }
        ],
        styles: {
          header: {
            fontSize: 24,
            bold: true,
            color: '#000080',
          },
          schoolName: {
            fontSize: 18,
            color: '#000080',
          },
          eventName: {
            fontSize: 16,
            color: '#000080',
          },
          tableHeader: {
            bold: true,
            fillColor: '#f8f9fa'
          },
          disclaimer: {
            fontSize: 10,
            color: '#666666',
          },
        },
      };
    },
    async generatePDFs() {
      try {
        // First, get the data and wait for it
        const participantData = await this.getParticipants(this.event.id);
        this.data = participantData;

        // Load the logo image
        const logoUrl = require('/public/images/nsw-govt-logo.png');
        const logoData = await this.getBase64Image(logoUrl);

        // Now we can safely group students since we have the data
        const schoolGroups = this.groupStudentsBySchool(
          participantData.students
        );

        const eventDetails = {
          eventName: participantData.eventName,
          startDate: participantData.startDate,
          endDate: participantData.endDate,
          category: participantData.category,
          vaCombined: participantData.vaCombined,
        };

        // Generate and download PDF for each school
        for (const [, schoolData] of Object.entries(schoolGroups)) {
          const docDefinition = await this.createSchoolPDFDefinition(
            schoolData,
            logoData,
            eventDetails
          );

          const safeSchoolName = schoolData.schoolName
            .toLowerCase()
            .replace(/[^a-z0-9]/g, '-');
          const filename = `attendance-sheet-${safeSchoolName}.pdf`;

          pdfMake.fonts = {
            Roboto: {
              normal:
                'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.12/fonts/Roboto/Roboto-Regular.ttf',
              bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.12/fonts/Roboto/Roboto-Medium.ttf',
              italics:
                'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.12/fonts/Roboto/Roboto-Italic.ttf',
              bolditalics:
                'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.12/fonts/Roboto/Roboto-MediumItalic.ttf',
            },
          };

          pdfMake.createPdf(docDefinition).download(filename);
        }
      } catch (error) {
        // console.error('Error generating PDFs:', error);
        // Add error handling UI feedback here if needed
      }
    },
  },
};
</script>