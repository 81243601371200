<!-- DisclaimerModal.vue -->
<template>
  <v-dialog
    v-model="dialog"
    max-width="700px"
    persistent
  >
    <template #activator="{ on, attrs }">
      <!-- Default button if no slot is provided -->
      <slot
        name="activator"
        v-bind="{ on, attrs }"
      >
        <button
          v-bind="attrs"
          v-on="on"
        >
          Print student attendance list
        </button>
      </slot>
    </template>

    <v-card>
      <v-container>
        <v-card-title class="d-flex align-center pa-4">
          <v-avatar
            color="light-blue lighten-5"
            size="40"
            class="mr-3"
          >
            <v-icon color="blue darken-4">
              security
            </v-icon>
          </v-avatar>
          <span
            class=""
            style="color: #002664; font-size: 20px; font-weight: 700;"
          >Data privacy disclaimer</span>
          <v-spacer />
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12">
              <p class="text-body-1">
                This document must be printed only if the user does not have internet access in the school to mark attendance on the EPP initiative management application. Please ensure that only the minimum required number of copies are made, the printed copy is not provided to third parties, and any printed pages are not left unattended.
              </p>
              <p class="text-body-1 mt-4">
                Once the attendance information has been entered into the application, the physical copy must be destroyed.
              </p>
            </v-col>
            <v-col
              cols="12"
              class="pt-4"
            >
              <div
                class="mb-4"
                style="color: #002664; font-size: 16px; font-weight: 700;"
              >
                Confirmation
              </div>
              <div class="confirmation-box">
                <v-checkbox
                  v-model="agreed"
                  color="primary"
                  class="mt-0"
                >
                  <template #label>
                    <div class="text-body-1">
                      I have read the privacy disclaimer and understand the conditions
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            text
            color="blue darken-1"
            @click="dialog = false"
            class="text-capitalize"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!agreed"
            @click="onConfirm"
            class="text-capitalize"
          >
            Continue
            <v-icon right>
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DisclaimerModal',
  data() {
    return {
      dialog: false,
      agreed: false
    }
  },
  methods: {
    onConfirm() {
      if (this.agreed) {
        this.$emit('confirm')
        this.dialog = false
        this.agreed = false
      }
    }
  }
}
</script>

<style scoped>
.v-card__title {
  word-break: normal;
}

.confirmation-box {
  border-radius: 4px;
  border: 1px solid #CDD3D6;
  padding: 12px;
}

.v-messages.theme--light {
  display: none !important;
  min-height: 0;
}
</style>