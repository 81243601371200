import { post } from '../http';

const getEnrolments = (schoolIds) => post('enrolmentRetrieve', { schoolIds });

const save = (id, data) => {
  let url = 'registrantsSave';
  if (id) {
    url += '?id=' + id;
  }
  return post(url, data);
};

const getExistingRegistrations = (event_id) =>
  post('registrantsRetrieve', { event_id });

const getParticipants = (start_date, end_date) =>
  post('participantsRetrieve', { start_date, end_date });

const deleteRegistrant = (id) => post('registrantDelete', { id });

const markAttendance = (student_ids, status) =>
  post('registrantsAttendance', { student_ids, status });

const withdraw = (student_ids) => post('registrantWithdraw', { student_ids });

export default {
  getEnrolments,
  save,
  getExistingRegistrations,
  deleteRegistrant,
  markAttendance,
  withdraw,
  getParticipants,
};
