<template>
  <v-container>
    <v-form
      ref="newEventForm"
      :key="formKey"
    >
      <v-stepper
        v-model="newEventStep"
        vertical
      >
        <v-stepper-step
          :complete="newEventStep > 1"
          step="1"
        >
          Event Details
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-row>
            <v-col cols="12">
              <Alert
                showAlert
                type="info"
                htmlText="<p>To create an event you will need to know:</p><ul>
                  <p>- When your event will run; <br />- the address (physical address for in person events, or access link/URL for online events); <br />- initiative type; <br />- event category; <br />- activity type; <br />- who your audience is.</p>
                <p>If you encounter problems, please email the program team.</p>"
                inPage
                actionText="Email program team"
                :actionHandler="emailProgramTeam"
                :backgroundColour="true"
                allowDismiss
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.title"
                label="Event title *"
                placeholder="Enter event title"
                :rules="[validation.required]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="newEvent.initiative_type"
                label="Initiative type *"
                :items="filteredInitiativeTypes"
                placeholder="Please select"
                :rules="[validation.required]"
              />
            </v-col>
          </v-row>

          <v-switch
            v-if="newEvent.initiative_type == 'Innovating Careers Education' || newEvent.initiative_type == 'Job Readiness'"
            style="margin-top: 0"
            v-model="newEvent.combined_event"
            label="VA combined event"
          />

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="newEvent.category"
                label="Category *"
                :items="filteredCategories"
                placeholder="Please select"
                :rules="[validation.required]"
                :hint="registrationRequiredMessage"
                persistent-hint
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="newEvent.activity_type"
                label="Activity type *"
                :items="filteredActivities"
                :rules="[validation.required]"
                placeholder="Please select"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsTextArea
                v-model="newEvent.description"
                label="Description *"
                :rules="[validation.required]"
                placeholder="Enter event description"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="newEvent.presenter_type"
                label="Presenter type"
                hint="If unknown, this can be completed later"
                persistent-hint
                :items="presenterTypes"
                placeholder="Please select"
              />
            </v-col>
          </v-row>

          <v-row v-if="newEvent.presenter_type == 'Employer'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_number"
                label="Number of employers"
                hint="If unknown, this can be completed later"
                persistent-hint
                :rules="[validation.optionalNumber]"
              />
            </v-col>
          </v-row>
          <v-row v-if="newEvent.presenter_type == 'Employer'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_name"
                label="Employer name"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="newEvent.presenter_type == 'GTO'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_name"
                label="Name of GTO"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="newEvent.presenter_type == 'HTC'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_number"
                label="Number of HTCs"
                hint="If unknown, this can be completed later"
                persistent-hint
                :rules="[validation.optionalNumber]"
              />
            </v-col>
          </v-row>
          <v-row v-if="newEvent.presenter_type == 'HTC'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_name"
                label="Name of HTC"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="newEvent.presenter_type == 'Job Readiness Provider'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_number"
                label="Number of presenters from job readiness"
                hint="If unknown, this can be completed later"
                persistent-hint
                :rules="[validation.optionalNumber]"
              />
            </v-col>
          </v-row>
          <v-row v-if="newEvent.presenter_type == 'RIEP'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_number"
                label="Number of presenters from RIEP"
                :rules="[validation.optionalNumber]"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="newEvent.presenter_type == 'RTO'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_name"
                label="Name of RTO"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="newEvent.presenter_type == 'SBATEO'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_number"
                label="Number of SBATEOs"
                :rules="[validation.optionalNumber]"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="newEvent.presenter_type == 'SBATEO'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_name"
                label="SBATEO names"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="newEvent.presenter_type == 'VET Ambassadors'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_number"
                label="Number of VET Ambassadors"
                :rules="[validation.optionalNumber]"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="newEvent.presenter_type == 'VET Ambassadors'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_name"
                label="VET Ambassador names"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="newEvent.presenter_type == 'Other'">
            <v-col cols="12">
              <AdsTextField
                v-model="newEvent.presenter_other"
                label="Please specify"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>

          <Alert
            v-if="showStepError"
            showAlert
            type="error"
            text="Please complete all required fields indicated by a *"
            inPage
            :backgroundColour="true"
          />
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="moveToStep(2)"
              >
                Continue
              </v-btn>
              <v-btn
                text
                @click="toggleDialog()"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-step
          :complete="newEventStep > 2"
          step="2"
        >
          Scheduling
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-row>
            <v-col cols="12">
              <p><strong>Date and time</strong></p>
            </v-col>
            <v-col cols="6">
              <DatePicker
                v-model="newEvent.start_date"
                label="Start date *"
                id="start_date"
                :rules="[validation.required]"
              />
            </v-col>
            <v-col cols="6">
              <DatePicker
                v-model="newEvent.end_date"
                label="End date *"
                id="end_date"
                :rules="[validation.required]"
                :datePickerAttrs="{ min: newEvent.start_date }"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-dialog
                ref="startTimeDialog"
                v-model="startTimeDialog"
                width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="newEvent.start_time"
                    label="Start time *"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="newEvent.start_time"
                  full-width
                  ampm-in-title
                  format="ampm"
                  :rules="[validation.required]"
                />
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-dialog
                ref="endTimeDialog"
                v-model="endTimeDialog"
                width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="newEvent.end_time"
                    label="End time *"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="newEvent.end_time"
                  full-width
                  ampm-in-title
                  format="ampm"
                  :rules="[validation.required]"
                />
              </v-dialog>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AdsSelect
                v-model="newEvent.term"
                label="Term *"
                :items="terms"
                placeholder="Please select"
                :rules="[validation.required]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <p><strong>Location</strong></p>
            </v-col>

            <v-col cols="12">
              <div style="font-weight: bold">
                Delivery *
              </div>
              <v-radio-group
                v-model="newEvent.format"
                row
                @change="setupGoogleMapsAutocomplete"
              >
                <v-radio
                  v-for="(format, index) in formats"
                  :label="format"
                  :value="format"
                  :key="index"
                />
              </v-radio-group>
            </v-col>

            <v-col>
              <AdsTextField
                v-model="newEvent.venue_address"
                label="Enter a location *"
                :rules="[validation.required]"
                placeholder="Type address"
                v-if="newEvent.format == 'In-person'"
                ref="addressInput"
                autocomplete="off"
              />

              <AdsTextField
                v-model="newEvent.access_link"
                label="Access link *"
                :rules="[validation.required, validation.url]"
                placeholder="Type URL"
                v-if="newEvent.format == 'Online'"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AdsTextField
                v-model="newEvent.capacity"
                label="Capacity *"
                placeholder="Please enter a number"
                :rules="[validation.number]"
              />
            </v-col>
          </v-row>

          <Alert
            v-if="showStepError"
            showAlert
            type="error"
            text="Please complete all required fields indicated by a *"
            inPage
            :backgroundColour="true"
          />
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="moveToStep(3)"
              >
                Continue
              </v-btn>
              <v-btn
                text
                @click="newEventStep = 1"
              >
                Previous
              </v-btn>
              <v-btn
                text
                @click="toggleDialog()"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-step
          :complete="newEventStep > 3"
          step="3"
        >
          Participation
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12">
              <p><strong>School type *</strong></p>

              <v-radio-group
                v-model="newEvent.school_type"
                row
              >
                <v-radio
                  label="EPP school"
                  value="EPP school"
                />
                <v-radio
                  label="Flexible delivery school"
                  value="Flexible delivery school"
                />
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AdsSelect
                v-model="newEvent.selected_region_ids"
                label="Region *"
                :items="filteredRegions"
                placeholder="Please select"
                item-text="name"
                item-value="id"
                :rules="[validation.required]"
                multiple
                chips
              />
            </v-col>

            <v-col cols="6">
              <AdsSelect
                v-model="newEvent.selected_group_ids"
                label="School group *"
                :items="filteredSchoolGroups"
                placeholder="Please select"
                item-text="name"
                item-value="id"
                :rules="[validation.required]"
                multiple
                chips
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="newEvent.schools"
                label="Schools *"
                :items="filteredSchools"
                :rules="[validation.required]"
                placeholder="Please select"
                item-text="name"
                item-value="id"
                multiple
                chips
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="newEvent.audiences"
                label="Audiences *"
                :items="filteredAudiences"
                multiple
                chips
                :rules="[validation.required]"
                placeholder="Please select"
              />
            </v-col>
          </v-row>

          <Alert
            v-if="showStepError"
            showAlert
            type="error"
            text="Please complete all required fields indicated by a *"
            inPage
            :backgroundColour="true"
          />

          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="saveNewEvent"
              >
                Create Event
              </v-btn>
              <v-btn
                text
                @click="newEventStep = 2"
              >
                Previous
              </v-btn>
              <v-btn
                text
                @click="toggleDialog()"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper>
    </v-form>
  </v-container>
</template>

<script>
/* global google */

import { ADS_Colors, AdsTextField, AdsSelect, AdsTextArea, DatePicker, Alert } from '@nswdoe/doe-ui-core';

import { useAuthStore } from '@/stores/auth';
import { useInitiativeTypesStore } from '@/stores/initiativeTypes';
import { useCategoriesStore } from '@/stores/categories';
import { useActivityTypesStore } from '@/stores/activityTypes';
import { useTermsStore } from '@/stores/terms';
import { useFormatsStore } from '@/stores/formats';
import { useAudiencesStore } from '@/stores/audiences';
import { usePresenterTypesStore } from '@/stores/presenterTypes';

import { zonedTimeToUtc } from 'date-fns-tz';
import { mapActions } from 'pinia';
import { useErrorDialogStore } from '@/stores/errorDialogStore';

export default {
  name: 'CreateEvent',
  components: {
    AdsTextField,
    AdsSelect,
    AdsTextArea,
    DatePicker,
    Alert,
  },
  data() {
    return {
      formKey: 0,
      user: {},
      showStepError: false,
      startTimeDialog: false,
      endTimeDialog: false,
      newEventStep: 1,
      ADS_Colors,
      events: [],
      newEvent: this.createNewEvent(),
      validation: {
        required: (value) => !!value || 'This field is required',
        number: (value) => {
          if (!value) return 'This field is required';
          const number = parseFloat(value);
          return (!isNaN(number) && /^\d+(\.\d+)?$/.test(value) && number > 0) || 'Please enter a number greater than 0';
        },
        optionalNumber: (value) => {
          if (!value) return true; // Allow empty value
          const number = parseFloat(value);
          return (!isNaN(number) && /^\d+(\.\d+)?$/.test(value) && number > 0) || 'Please enter a number greater than 0';
        },
        url: (value) =>
          !value ||
          new RegExp('^(https?:\\/\\/)([\\da-z.-]+)\\.([a-z.]{2,6})([\\/\\w0-9 .,\\-_"!?@#$%^&*()+={}<>\']*)*\\/?$').test(value) ||
          'Please enter a valid url, including https:// or http://',
      },
      schools: [],
      transformedSchoolData: [],
      regions: [],
      schoolGroups: [],
    };
  },
  watch: {
    'newEvent.start_date'(newStartDate) {
      if (newStartDate && (!this.newEvent.end_date || new Date(this.newEvent.end_date) < new Date(newStartDate))) {
        this.newEvent.end_date = newStartDate;
      }
    },
    'newEvent.presenter_type'() {
      this.newEvent.presenter_number = null;
      this.newEvent.presenter_name = null;
    },
    'newEvent.initiative_type'() {
      this.newEvent.category = null;
      this.newEvent.activity_type = null;
    },
    'newEvent.selected_region_ids'() {
      this.newEvent.selected_group_ids = [];
      this.newEvent.schools = [];
    },
    'newEvent.selected_group_ids'() {
      this.newEvent.schools = [];
    },
    'newEvent.school_type'() {
      this.newEvent.selected_region_ids = [];
      this.newEvent.selected_group_ids = [];
      this.newEvent.schools = [];
    },
  },
  computed: {
    filteredRegions() {
      // Check if user is "Super Admin" and return all regions if true
      if (this.user && this.user.role === 'Super Admin') {
        return this.regions;
      }

      // Proceed with filtering for "Event Admin"
      if (!this.user || !Array.isArray(this.user.regions)) return [];
      return this.regions.filter((region) => this.user.regions.includes(region.id));
    },
    filteredSchoolGroups() {
      // Check if user is "Super Admin" to determine if filtering by user's groups is necessary
      const isSuperAdmin = this.user && this.user.role === 'Super Admin';

      // Always proceed with filtering based on selected region IDs
      if (!this.newEvent.selected_region_ids || this.newEvent.selected_region_ids.length === 0) {
        return [];
      }

      // Filter school groups by region. If user is not "Super Admin", additionally filter by user's allowed groups.
      const userAllowedSchoolGroups = this.user && Array.isArray(this.user.groups) ? this.user.groups : [];
      return this.schoolGroups.filter(
        (group) =>
          this.newEvent.selected_region_ids.includes(group.region_id) && (isSuperAdmin || userAllowedSchoolGroups.includes(group.id))
      );
    },
    filteredSchools() {
      let schools = [];
      for (let region of this.schools) {
        for (let group of region.groups) {
          for (let school of group.schools) {
            if (
              school.status === 'Active' &&
              (!this.newEvent.selected_group_ids || this.newEvent.selected_group_ids.includes(school.school_group_id)) &&
              (!this.newEvent.school_type || this.newEvent.school_type === school.school_type)
            ) {
              schools.push(school);
            }
          }
        }
      }
      return schools;
    },
    registrationRequiredMessage() {
      if (
        this.newEvent.category == 'CIT Meeting' ||
        this.newEvent.category == 'Executive Presentation' ||
        this.newEvent.category == 'Updates to School Teams' ||
        this.newEvent.category == 'Professional Learning'
      ) {
        return 'Registration is not available for this Category';
      } else if (
        this.newEvent.category == 'Student / School Event or Activity' ||
        this.newEvent.category == 'Job Readiness Workshop' ||
        this.newEvent.category == 'Apprenticeship and Traineeship Headstart' ||
        this.newEvent.category == 'VET Ambassador Event'
      ) {
        return 'If your audience includes Students, registration will be required for this Category';
      }

      return '';
    },
    initiativeTypes() {
      const initiativeTypesStore = useInitiativeTypesStore();
      return initiativeTypesStore.items;
    },
    filteredInitiativeTypes() {
      // Check if user is "Super Admin" and return all initiative types if true
      if (this.user && this.user.role === 'Super Admin') {
        const initiativeTypesStore = useInitiativeTypesStore();
        return initiativeTypesStore.items;
      }

      // Proceed with filtering for "Event Admin"
      const userAllowedTypes = Array.isArray(this.user.initiative_types) ? this.user.initiative_types : [];
      const initiativeTypesStore = useInitiativeTypesStore();
      return initiativeTypesStore.items.filter((type) => userAllowedTypes.includes(type));
    },
    categories() {
      const categories = useCategoriesStore();
      return categories.items;
    },
    filteredCategories() {
      if (!this.newEvent.initiative_type) {
        return this.categories;
      }
      if (this.newEvent.initiative_type == 'VET Ambassadors') {
        return ['VET Ambassador Event'];
      }

      if (this.newEvent.initiative_type == 'Job Readiness') {
        return ['Job Readiness Workshop'];
      }

      if (this.newEvent.initiative_type == 'Apprenticeship and Traineeship Headstart') {
        return ['Apprenticeship and Traineeship Headstart'];
      }

      if (this.newEvent.initiative_type == 'Innovating Careers Education') {
        return [
          'CIT Meeting',
          'Executive Presentation',
          'Professional Learning',
          'Student / School Event or Activity',
          'Updates to School Teams',
        ];
      }

      return this.categories;
    },
    activityTypes() {
      const activityTypes = useActivityTypesStore();
      return activityTypes.items;
    },
    filteredActivities() {
      if (!this.newEvent.initiative_type) {
        return this.activityTypes;
      }
      if (this.newEvent.initiative_type == 'VET Ambassadors') {
        return ['Presentation Session'];
      }

      if (this.newEvent.initiative_type == 'Job Readiness') {
        return ['Workshop'];
      }

      if (this.newEvent.initiative_type == 'Apprenticeship and Traineeship Headstart') {
        return ['Course'];
      }

      return this.activityTypes;
    },
    terms() {
      const terms = useTermsStore();
      return terms.items;
    },
    formats() {
      const formats = useFormatsStore();
      return formats.items;
    },
    audiences() {
      const audiences = useAudiencesStore();
      return audiences.items;
    },
    filteredAudiences() {
      if (!this.newEvent.category) {
        return this.audiences;
      }
      if (
        this.newEvent.category == 'CIT Meeting' ||
        this.newEvent.category == 'Executive Presentation' ||
        this.newEvent.category == 'Updates to School Teams' ||
        this.newEvent.category == 'Professional Learning'
      ) {
        return this.audiences.filter((audience) => audience !== 'High school students');
      }

      return this.audiences;
    },
    presenterTypes() {
      const presenterTypes = usePresenterTypesStore();
      return presenterTypes.items;
    },
    loggedInUserRole() {
      const auth = useAuthStore();
      return auth.role;
    },
  },
  mounted() {
    Promise.all([
      this.$api.regions.fetch().then((results) => {
        this.regions = results.regions;
      }),

      this.$api.schools.fetchNestedData().then((results) => {
        this.schools = results;
      }),

      this.$api.schools.fetchGroups().then((results) => {
        this.schoolGroups = results.groups;
      }),

      this.$api.user.fetch().then((results) => {
        this.user = results.user;
      }),
    ]).catch(() => {});

    this.setupGoogleMapsAutocomplete();
  },
  methods: {
    ...mapActions(useErrorDialogStore, { toggleServerError: 'toggle' }),
    createNewEvent() {
      return {
        id: null,
        title: null,
        initiative_type: null,
        category: null,
        activity_type: null,
        description: null,
        combined_event: false,
        presenter_type: null,
        presenter_number: null,
        presenter_name: null,
        presenter_other: null,
        start_date: null,
        end_date: null,
        start_time: '09:00',
        end_time: '15:00',
        start_datetime: null,
        end_datetime: null,
        term: null,
        venue_address: null,
        google_places_address: null,
        access_link: null,
        format: 'In-person',
        capacity: null,
        school_type: 'EPP school',
        schools: [],
        audiences: [],
        status: 'available',
        selected_group_ids: [],
        selected_region_ids: [],
      };
    },
    emailProgramTeam() {
      window.location.href = 'mailto:educational.pathways@det.nsw.edu.au';
    },
    setupGoogleMapsAutocomplete() {
      // Ensure the input exists and format is In-person before setting up autocomplete
      this.$nextTick(() => {
        if (typeof google !== 'undefined' && this.$refs.addressInput && this.newEvent.format === 'In-person') {
          const input = this.$refs.addressInput.$el.querySelector('input');
          const autocomplete = new google.maps.places.Autocomplete(input, {
            componentRestrictions: { country: 'au' },
          });

          // Listen for the place_changed event
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.formatted_address) {
              this.newEvent.venue_address = place.formatted_address;
            }
          });
        }
      });
    },
    allowedDates: (val) => parseInt(val.split('-')[2], 10) % 2 === 0,
    isValidStep(step) {
      let isValid = true;

      // Validate step 1 fields
      if (step === 1) {
        isValid =
          !!this.newEvent.title &&
          !!this.newEvent.initiative_type &&
          !!this.newEvent.category &&
          !!this.newEvent.activity_type &&
          !!this.newEvent.description &&
          this.validation.optionalNumber(this.newEvent.presenter_number) === true;
      }

      // Validate step 2 fields
      if (step === 2) {
        isValid =
          !!this.newEvent.start_date &&
          !!this.newEvent.end_date &&
          !!this.newEvent.start_time &&
          !!this.newEvent.end_time &&
          !!this.newEvent.term &&
          this.validation.number(this.newEvent.capacity) === true &&
          !!this.newEvent.format &&
          (this.newEvent.format === 'In-person'
            ? !!this.newEvent.venue_address
            : !!this.newEvent.access_link && this.validation.url(this.newEvent.access_link) === true);
      }

      // Validate step 3 fields
      if (step === 3) {
        isValid =
          !!this.newEvent.school_type &&
          Array.isArray(this.newEvent.schools) &&
          this.newEvent.schools.length > 0 &&
          Array.isArray(this.newEvent.audiences) &&
          this.newEvent.audiences.length > 0;
      }

      return isValid;
    },
    moveToStep(targetStep) {
      this.showStepError = false;
      if (this.isValidStep(this.newEventStep)) {
        this.newEventStep = targetStep;
      } else {
        this.showStepError = true;
      }
    },
    saveNewEvent() {
      this.showStepError = false;
      if (this.isValidStep(this.newEventStep)) {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's time zone

        const startInUserTimezone = `${this.newEvent.start_date}T${this.newEvent.start_time}:00`;
        const endInUserTimezone = `${this.newEvent.end_date}T${this.newEvent.end_time}:00`;

        // Convert user's time to UTC using date-fns-tz
        this.newEvent.start_datetime = zonedTimeToUtc(startInUserTimezone, userTimezone).toISOString();
        this.newEvent.end_datetime = zonedTimeToUtc(endInUserTimezone, userTimezone).toISOString();

        this.$api.events
          .save(null, this.newEvent)
          .then((response) => {
            this.newEvent.id = response.id;
            this.toggleDialog(true);
          })
          .catch(() => {
            this.handleError();
            this.toggleDialog(false);
          });
      } else {
        this.showStepError = true;
      }
    },
    handleError() {
      this.toggleServerError();
    },
    toggleDialog(newEventCreated = false) {
      this.$emit('eventAdded', {
        title: this.newEvent.title,
        eventCreated: newEventCreated,
      });
      this.$refs.newEventForm.reset();
      this.newEvent = this.createNewEvent();
      this.newEventStep = 1;
      this.formKey += 1;
      this.setupGoogleMapsAutocomplete();
    },
  },
};
</script>

<style lang="scss" scoped></style>
