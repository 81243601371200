import Vue from 'vue';
import VueRouter from 'vue-router';
import Events from '@/views/Events';
import EventDetail from '@/views/Events/Detail';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'events',
    component: Events,
  },
  {
    path: '/event/:id',
    name: 'event_detail',
    component: EventDetail,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard'),
  },
  {
    path: '/reporting',
    name: 'reporting',
    component: () => import('../views/Reporting'),
  },
  {
    path: '/no-access',
    name: 'no-access',
    component: () => import('../views/NoAccess'),
  },
  {
    path: '/faq',
    name: 'faq',
    // route level code-splitting
    // this generates a separate chunk (faq.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faq" */ '../views/Faq'),
  },
  {
    path: '*',
    component: () => import('../views/PageNotFound'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
