<template>
  <v-container>
    <v-row>
      <v-col>
        <!-- Left Panel: Search and List -->
        <v-card>
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                class="headline"
              >
                Search
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  placeholder="Search"
                  single-line
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
          </v-card-title>

          <!-- Chip Filters in a Row -->
          <v-row
            no-gutters
            style="padding: 0 16px 20px"
          >
            <v-col cols="auto">
              Show students from:
            </v-col>

            <!-- School Filter -->
            <v-col
              cols="auto"
              class="pl-2"
            >
              <ChipFilter
                v-model="selectedSchool"
                name="School"
                type="radio"
                :items="availableSchools"
              />
            </v-col>

            <!-- Year Level Filter -->
            <v-col
              cols="auto"
              class="pl-2"
            >
              <ChipFilter
                v-model="selectedYearLevels"
                type="checkbox"
                :items="availableYears"
                heading="Year level"
                name="Year level"
                v-if="availableYears.length > 0"
              />
            </v-col>
          </v-row>

          <v-card-actions>
            <v-checkbox
              :input-value="isAllSelected"
              :indeterminate="isIndeterminate"
              @click.stop="toggleAllSelection"
            />
            <span>Select all</span>
            <v-spacer />
            <AdsButton
              icon="add_circle_outline"
              buttonText="Register students"
              @click="registerSelected()"
            />
          </v-card-actions>

          <v-divider />

          <v-list style="max-height: 50vh; overflow-y: auto">
            <div
              v-if="isLoading"
              class="pa-4"
            >
              Loading students...
            </div>
            <div
              v-else-if="!selectedSchool"
              class="pa-4"
            >
              Please select a school to see students for registration.
            </div>
            <div
              v-else-if="filteredStudents.length === 0"
              class="pa-4"
            >
              No students found.
            </div>
            <v-list-item-group
              v-model="selectedStudentSRNs"
              multiple
              active-class="primary--text"
            >
              <v-list-item
                v-for="student in filteredStudents"
                :key="student.studentRegistrationNumber"
                :class="{ 'greyed-out': isRegistered(student) }"
                @click="toggleSelection(student)"
              >
                <v-list-item-action>
                  <v-checkbox
                    :input-value="isSelected(student)"
                    :disabled="isRegistered(student)"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ student.givenName }}
                    {{ student.familyName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ student.schoolName }} | Year {{ student.scholasticYearCode }} | SRN:
                    {{ student.studentRegistrationNumber }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="isRegistered(student)">
                  <span>Registered</span>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col>
        <!-- Right Panel: New Registrants -->
        <v-card>
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                class="headline"
              >
                New registrants
              </v-col>
              <v-col cols="12">
                <v-card-actions>
                  <span class="subheading mb-2">
                    <strong>{{ availableSpots }}</strong>
                    /{{ event.capacity }} spots available
                  </span>
                  <v-spacer />
                  <AdsButton
                    buttonText="Save and close"
                    :loading="saveIsLoading"
                    :disabled="saveIsLoading"
                    @click="saveRegistrations"
                  />
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-list style="max-height: 50vh; overflow-y: auto">
              <v-list-item
                v-for="student in newRegistrants"
                :key="student.studentRegistrationNumber"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ student.givenName }}
                    {{ student.familyName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ student.schoolName }} | Year
                    {{ student.scholasticYearCode }}
                    <span v-if="student.studentRegistrationNumber">| SRN: {{ student.studentRegistrationNumber }}</span>
                  </v-list-item-subtitle>
                  <div v-if="isSchoolInactive(student.schoolId)">
                    <v-icon
                      color="error"
                      small
                      class="mr-1 material-icons-outlined"
                    >
                      error
                    </v-icon>
                    <span
                      class="text-caption"
                      style="color: #333"
                    >
                      Inactive school
                    </span>
                  </div>
                </v-list-item-content>
                <v-list-item-action @click="removeRegistrant(student)">
                  <v-icon>mdi-close</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item v-if="newRegistrants.length === 0">
                <v-list-item-content>You haven't added any registrants yet.</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AdsButton, ChipFilter } from '@nswdoe/doe-ui-core';
import { mapActions } from 'pinia';
import { useErrorDialogStore } from '@/stores/errorDialogStore';

export default {
  name: 'StudentRegistration',
  data() {
    return {
      enrolments: [],
      isIndeterminate: false,
      search: '',
      selectedStudentSRNs: [],
      selectedYearLevels: [],
      selectedSchools: [],
      students: [],
      newRegistrants: [],
      existingRegistrations: [], // Ensure this is initialized
      selectedSchool: '',
      allSchools: [],
      isLoading: false,
      saveIsLoading: false,
      componentKey: 0
    };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    isDialogOpen: {
      type: Boolean,
      required: true
    }
  },
  components: {
    AdsButton,
    ChipFilter,
  },
  created() {
    // Initialize when component is first created
    this.initializeComponent();
  },
  beforeDestroy() {
    // Reset all state when component is destroyed
    this.resetState();
  },
  watch: {
    isDialogOpen: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          // Dialog was opened - reinitialize
          this.initializeComponent();
        }
      }
    },
    event: {
      immediate: true,
      handler(newEvent, oldEvent) {
        if (newEvent && (!oldEvent || newEvent.id !== oldEvent.id)) {
          this.initializeComponent();
        }
      }
    },
    selectedSchool: {
      handler(newSchoolId, oldSchoolId) {
        if (newSchoolId !== oldSchoolId) {
          this.students = [];
          this.isLoading = true;
          this.fetchStudentsBySchool();
        }
      }
    }
  },
  computed: {
    isSchoolInactive() {
      return (schoolId) => {
        const school = this.allSchools.find((school) => school.id === schoolId);
        return school && school.status === 'Inactive';
      };
    },
    selectedStudents() {
      return this.students
        .filter((student) => this.selectedStudentSRNs.includes(student.studentRegistrationNumber))
        .map((student) => ({
          ...student,
          event_id: this.event.id,
        }));
    },
    availableSpots() {
      return this.event.capacity - this.newRegistrants.length;
    },
    availableStudentsForSelection() {
      return this.filteredStudents.filter(student => !this.isRegistered(student));
    },
    isAllSelected() {
      const availableStudents = this.availableStudentsForSelection;
      return availableStudents.length > 0 &&
        this.selectedStudents.length === availableStudents.length;
    },
    availableYears() {
      const years = new Set(this.students.map((student) => student.scholasticYearCode));
      return Array.from(years)
        .sort((a, b) => a - b)
        .map((year) => {
          return { id: String(year), name: `Year ${year}` };
        });
    },
    availableSchools() {
      if (this.allSchools && this.allSchools.length > 0 && this.event && this.event.schools && this.event.schools.length > 0) {
        return this.allSchools
          .filter((school) => this.event.schools.includes(school.id) && school.status === 'Active')
          .map((school) => {
            return {
              id: school.id,
              name: school.name,
            };
          });
      }
      return [];
    },
    filteredStudents() {
      const searchLower = this.search.toLowerCase();
      return this.students
        .filter((student) => {
          const matchesYearLevel = !this.selectedYearLevels.length || this.selectedYearLevels.includes(student.scholasticYearCode);
          const matchesSchool =
            !this.selectedSchools.length ||
            this.availableSchools
              .filter((school) => this.selectedSchools.includes(school.id))
              .map((school) => school.name)
              .includes(student.schoolName);
          const matchesSearch = (student.givenName + ' ' + student.familyName).toLowerCase().includes(searchLower);
          return matchesYearLevel && matchesSchool && matchesSearch;
        })
        .sort((a, b) => {
          if (a.schoolName < b.schoolName) return -1;
          if (a.schoolName > b.schoolName) return 1;
          if (a.familyName < b.familyName) return -1;
          if (a.familyName > b.familyName) return 1;
          return 0;
        });
    },
  },
  methods: {
    resetState() {
      // Reset all component state to initial values
      this.enrolments = [];
      this.isIndeterminate = false;
      this.search = '';
      this.selectedStudentSRNs = [];
      this.selectedYearLevels = [];
      this.selectedSchools = [];
      this.students = [];
      this.newRegistrants = [];
      this.existingRegistrations = [];
      this.selectedSchool = '';
      this.allSchools = [];
      this.isLoading = false;
      this.saveIsLoading = false;
      this.componentKey++;
    },
    ...mapActions(useErrorDialogStore, { toggleServerError: 'toggle' }),
    async initializeComponent() {
      // Reset state before initializing
      this.resetState();

      // Start loading state
      this.isLoading = true;

      try {
        // Fetch all required data in parallel
        await Promise.all([
          this.fetchStudentsBySchool(),
          this.getAllSchools(),
          this.fetchExistingRegistrations()
        ]);
      } catch (error) {
        this.handleError();
      } finally {
        this.isLoading = false;
      }
    },
    async getAllSchools() {
      try {
        const response = await this.$api.schools.fetch();
        this.allSchools = response.schools;
      } catch (error) {
        this.handleError();
      }
    },
    async fetchStudentsBySchool() {
      if (!this.selectedSchool) {
        this.isLoading = false;
        return;
      }

      try {
        const data = await this.$api.registrants.getEnrolments([this.selectedSchool]);
        this.students = data;
      } catch (error) {
        this.students = [];
        this.handleError();
      } finally {
        this.isLoading = false;
      }
    },
    async fetchExistingRegistrations() {
      try {
        const response = await this.$api.registrants.getExistingRegistrations(this.event.id);
        this.existingRegistrations = response.registrants;
        this.newRegistrants = [...response.registrants];
      } catch (error) {
        this.handleError();
      }
    },
    handleError() {
      this.toggleServerError();
    },
    async saveRegistrations() {
      if (this.saveIsLoading) return;

      this.saveIsLoading = true;

      try {
        // Filter out students who are already registered
        const unsavedRegistrants = this.newRegistrants.filter(
          (student) => !this.existingRegistrations.some(
            (existing) => existing.studentRegistrationNumber === student.studentRegistrationNumber
          )
        );

        // If there are no unsaved registrants
        if (unsavedRegistrants.length === 0) {
          this.$emit('closeAndSave');
          return;
        }

        // Save the registrants
        await this.$api.registrants.save(null, { registrants: unsavedRegistrants });

        // Reset the component state
        await this.initializeComponent();

        // Close the dialog
        this.$emit('closeAndSave');

      } catch (error) {
        this.handleError();
        await this.initializeComponent();
        this.$emit('closeAndSave');
      } finally {
        this.saveIsLoading = false;
      }
    },
    removeRegistrant(student) {
      const index = this.newRegistrants.findIndex(
        (registrant) => registrant.studentRegistrationNumber === student.studentRegistrationNumber
      );

      if (student.id) {
        this.$api.registrants
          .deleteRegistrant(student.id)
          .then(() => {
            this.newRegistrants.splice(index, 1);
          })
          .catch(() => {
            this.handleError();
            this.newRegistrants = [];
            this.students = [];
            this.$emit('closeAndSave');
          });
      } else {
        this.newRegistrants.splice(index, 1);
      }
    },
    registerSelected() {
      const totalRegistrants = this.newRegistrants.length + this.selectedStudents.length;

      if (totalRegistrants > this.event.capacity) {
        alert(
          `You are trying to register ${totalRegistrants} students when there are only ${this.availableSpots} spots available. Please either reduce the number of registrations or increase the event capacity from the "Event Details Screen" and try again.`
        );
      } else {
        this.newRegistrants.push(...this.selectedStudents);
        this.selectedStudentSRNs = [];
      }
      this.updateIndeterminateState();
    },
    toggleSelection(student) {
      if (this.isRegistered(student)) {
        return;
      }
      const srnIndex = this.selectedStudentSRNs.indexOf(student.studentRegistrationNumber);
      if (srnIndex >= 0) {
        this.selectedStudentSRNs.splice(srnIndex, 1);
      } else {
        this.selectedStudentSRNs.push(student.studentRegistrationNumber);
      }
      this.updateIndeterminateState();
    },
    toggleAllSelection() {
      if (this.isAllSelected) {
        this.selectedStudentSRNs = [];
      } else {
        // Only select students that aren't already registered
        this.selectedStudentSRNs = this.availableStudentsForSelection
          .map(student => student.studentRegistrationNumber);
      }
      this.updateIndeterminateState();
    },
    isSelected(student) {
      return this.selectedStudentSRNs.includes(student.studentRegistrationNumber);
    },
    isRegistered(student) {
      return this.newRegistrants.some((registrant) => registrant.studentRegistrationNumber === student.studentRegistrationNumber);
    },
    updateIndeterminateState() {
      const availableStudents = this.availableStudentsForSelection;
      this.isIndeterminate =
        this.selectedStudents.length > 0 &&
        this.selectedStudents.length < availableStudents.length;
    },
    selectAll() {
      if (this.selectedStudents.length !== this.students.length) {
        this.selectedStudents = [...this.students];
      } else {
        this.selectedStudents = [];
      }
    },
  },
};
</script>

<style scoped>
.v-dialog .v-card__actions {
  padding: 0 16px 0 16px !important;
}

.greyed-out {
  color: #aaa;
  /* Grey text */
  background-color: #f5f5f5;
  /* Light grey background */
}
</style>
