<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <strong>Date</strong> <br> Please select date range
        </v-col>
        <v-col cols="2">
          <DatePicker
            :key="'start_' + datePickerKey"
            :value="start_date"
            @input="val => start_date = val"
            label="From *"
            id="start_date"
            :rules="[validation.required]"
          />
        </v-col>
        <v-col cols="2">
          <DatePicker
            :key="'end_' + datePickerKey"
            :value="end_date"
            @input="val => end_date = val"
            label="To *"
            id="end_date"
            :rules="[validation.required]"
          />
        </v-col>
        <v-col cols="8">
          <div style="display: flex; margin-top: 8px;">
            <AdsButton
              buttonText="Show results"
              @click="handleShowResults"
              :disabled="!start_date || !end_date"
            />
            <AdsButton
              style="margin-left: 20px;"
              buttonText="Clear dates"
              :tertiary="true"
              @click="clearDates"
              v-if="start_date || end_date"
            />
          </div>
        </v-col>
      </v-row>

      <template v-if="showContent">
        <v-row>
          <v-col cols="12">
            <AdsTextField
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              background-color="white"
              placeholder="Search"
              label="Search"
              single-line
              hide-details
              clearable
              :outlined="true"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="d-flex align-center flex-wrap">
              <label class="mr-4 text-no-wrap">Filter by:</label>
              <template v-for="(filter, key) in filters">
                <ChipFilter
                  v-if="filter.label !== 'Schools'"
                  v-model="selectedFilters[key]"
                  :key="key"
                  class="chipFilter ml-1"
                  :name="filter.label"
                  :type="filter.type"
                  :items="filter.items"
                  :heading="filter.label"
                />

                <ChipFilter
                  v-if="filter.label == 'Schools'"
                  v-model="selectedFilters[key]"
                  type="checkbox"
                  :items="transformedSchoolData"
                  :multi-level="true"
                  heading="Schools"
                  name="Schools"
                  :key="key"
                />
              </template>
              <AdsButton
                buttonText="Clear all"
                class="clearAll"
                tertiary
                @click="clearAll"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="participants"
              search-label="Find dessert"
            >
              <template #[`item.calories`]="{ item }">
                <v-chip color="orange">
                  {{ item.calories }}
                </v-chip>
              </template>

              <template #[`item.year`]="{ item }">
                Year {{ item.year }}
              </template>

              <template #[`item.status`]="{ item }">
                <Chip
                  :text="item.status"
                  :color="statusColour(item.status)"
                  style="text-transform: uppercase; font-weight: 700;"
                  small
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <v-row class="fill-height">
          <v-col
            cols="12"
            class="d-flex align-center justify-center"
            style="min-height: calc(100vh - 500px)"
          />
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import {
  DatePicker,
  AdsButton,
  AdsTextField,
  ChipFilter,
  Chip,
} from '@nswdoe/doe-ui-core';

import { useInitiativeTypesStore } from '@/stores/initiativeTypes';
import { useCategoriesStore } from '@/stores/categories';
import { useActivityTypesStore } from '@/stores/activityTypes';
import { useTermsStore } from '@/stores/terms';
import { useFormatsStore } from '@/stores/formats';
import { useAudiencesStore } from '@/stores/audiences';
import { usePresenterTypesStore } from '@/stores/presenterTypes';

export default {
  name: 'EPPParticipants',
  components: {
    DatePicker,
    AdsButton,
    AdsTextField,
    ChipFilter,
    Chip,
  },
  data() {
    return {
      datePickerKey: 0,
      tab: null,
      start_date: null,
      end_date: null,
      search: '',
      showContent: false, // Added: New data property to control content visibility
      selectedFilters: {
        initiative_types: [],
        category: [],
        activity_type: [],
        schools: [],
        term: [],
        format: [],
        audiences: [],
        vet_combined: [],
      },
      schools: [],
      transformedSchoolData: [],
      headers: [
        { text: 'Family name', value: 'family_name' },
        { text: 'Given name', value: 'given_name' },
        { text: 'SRN', value: 'srn' },
        { text: 'School', value: 'school' },
        { text: 'Year', value: 'year' },
        { text: 'Event', value: 'event' },
        { text: 'Start date', value: 'start_date' },
        { text: 'End date', value: 'end_date' },
        { text: 'Category', value: 'category' },
        { text: 'VA Combined', value: 'va_combined' },
        { text: 'Attendance', value: 'status' },
      ],
      rawParticipants: [],
      validation: {
        required: (value) => !!value || 'This field is required',
      },
    };
  },
  computed: {
    participants() {
      let filtered = [...this.rawParticipants];

      // Apply search filter
      if (this.search) {
        const searchLower = this.search.toLowerCase();
        filtered = filtered.filter((participant) =>
          participant.event?.toLowerCase().includes(searchLower)
        );
      }

      // Apply chip filters
      filtered = filtered.filter((participant) => {
        // Initiative types filter
        if (this.selectedFilters.initiative_types?.length > 0) {
          const match = this.selectedFilters.initiative_types.some(
            (filter) =>
              participant.initiative_type ===
              (typeof filter === 'string' ? filter : filter.name)
          );
          if (!match) return false;
        }

        // Category filter
        if (this.selectedFilters.category?.length > 0) {
          const match = this.selectedFilters.category.some(
            (filter) =>
              participant.category ===
              (typeof filter === 'string' ? filter : filter.name)
          );
          if (!match) return false;
        }

        // VA Combined filter
        if (this.selectedFilters.vet_combined?.length > 0) {
          const match = this.selectedFilters.vet_combined.some(
            (filter) =>
              participant.va_combined ===
              (typeof filter === 'string' ? filter : filter.name)
          );
          if (!match) return false;
        }

        // Activity type filter
        if (this.selectedFilters.activity_type?.length > 0) {
          const match = this.selectedFilters.activity_type.some(
            (filter) =>
              participant.activity_type ===
              (typeof filter === 'string' ? filter : filter.name)
          );
          if (!match) return false;
        }

        // Schools filter
        if (this.selectedFilters.schools?.length > 0) {
          const match = this.selectedFilters.schools.some((filter) => {
            const filterId = typeof filter === 'string' ? filter : filter.id;
            return participant.schoolId === filterId;
          });
          if (!match) return false;
        }

        // Term filter
        if (this.selectedFilters.term?.length > 0) {
          const match = this.selectedFilters.term.some(
            (filter) =>
              participant.term ===
              (typeof filter === 'string' ? filter : filter.name)
          );
          if (!match) return false;
        }

        // Format filter
        if (this.selectedFilters.format?.length > 0) {
          const match = this.selectedFilters.format.some(
            (filter) =>
              participant.format ===
              (typeof filter === 'string' ? filter : filter.name)
          );
          if (!match) return false;
        }

        // Audiences filter
        if (this.selectedFilters.audiences?.length > 0) {
          const match = this.selectedFilters.audiences.some((filter) => {
            const filterValue =
              typeof filter === 'string' ? filter : filter.name;
            return participant.audiences.includes(filterValue);
          });
          if (!match) return false;
        }

        return true;
      });

      return filtered;
    },
    initiativeTypes() {
      const store = useInitiativeTypesStore();
      return Array.isArray(store.items) ? store.items : [];
    },
    categories() {
      const store = useCategoriesStore();
      return Array.isArray(store.items) ? store.items : [];
    },
    activityTypes() {
      const store = useActivityTypesStore();
      return Array.isArray(store.items) ? store.items : [];
    },
    terms() {
      const store = useTermsStore();
      return Array.isArray(store.items) ? store.items : [];
    },
    formats() {
      const store = useFormatsStore();
      return Array.isArray(store.items) ? store.items : [];
    },
    audiences() {
      const store = useAudiencesStore();
      return Array.isArray(store.items) ? store.items : [];
    },
    presenterTypes() {
      const store = usePresenterTypesStore();
      return Array.isArray(store.items) ? store.items : [];
    },
    filters() {
      const filters = {
        initiative_types: {
          label: 'Initiative types',
          items: this.initiativeTypes,
        },
        category: {
          label: 'Event category',
          items: this.categories,
        },
        vet_combined: {
          label: 'VA combined',
          items: ['Yes', 'No'],
        },
        activity_type: {
          label: 'Activity type',
          items: this.activityTypes,
        },
        schools: {
          label: 'Schools',
          items: this.schools,
        },
        term: {
          label: 'Term',
          items: this.terms,
        },
        format: {
          label: 'Format',
          items: this.formats,
        },
        audiences: {
          label: 'Audiences',
          items: this.audiences,
        },
      };
      Object.values(filters).forEach((filter) => {
        filter.items = filter.items.map((name) => ({
          id: name,
          name,
        }));
      });

      return filters;
    },
  },
  mounted() {
    Promise.all([
      this.$api.schools.fetchNestedData().then((results) => {
        this.schools = results;
        this.transformedSchoolData = this.transformSchoolData(results);
      }),
    ]).catch(() => { });
  },
  methods: {
    // Add helper method for checking array intersections
    hasIntersection(arr1, arr2) {
      return arr1.some((item) => arr2.includes(item));
    },
    // Update clearAll method to handle arrays properly
    clearAll() {
      Object.keys(this.selectedFilters).forEach((key) => {
        this.selectedFilters[key] = [];
      });
      this.search = '';
    },
    // Added: New method to handle showing results
    handleShowResults() {
      if (this.start_date && this.end_date) {  // Both dates must exist
        this.fetchParticipants();
        this.showContent = true;
      }
    },
    fetchParticipants() {
      this.$api.registrants
        .getParticipants(this.start_date, this.end_date)
        .then((results) => {
          this.rawParticipants = results; // Set the raw data
        });
    },
    clearDates() {
      // First clear the values
      this.start_date = null;
      this.end_date = null;

      // Force re-render of date pickers
      this.datePickerKey += 1;

      // Reset the raw data
      this.rawParticipants = [];

      // Hide content
      this.showContent = false;

      // Clear all filters
      this.clearAll();

      // Use Vue 2's $nextTick to ensure DOM updates
      this.$nextTick(() => {
        this.$forceUpdate(); // Force a re-render of the component
      });
    },
    statusColour(status) {
      let colour;
      if (status === 'registered') {
        colour = '#407EC9';
      }
      if (status === 'attended') {
        colour = '#071E42';
      }
      if (status === 'unattended') {
        colour = '#94a3b8';
      }

      return colour;
    },
    transformSchoolData(data) {
      return data
        .map((item) => {
          const newItem = { ...item };
          delete newItem.region_id;
          delete newItem.last_updated;
          delete newItem.updated_by;
          delete newItem.school_group_id;
          delete newItem.category;

          if (newItem.groups) {
            newItem.children = this.transformSchoolData(newItem.groups);
            delete newItem.groups;
          }

          if (newItem.schools) {
            newItem.children = newItem.schools.map((school) => {
              const newSchool = { ...school };
              delete newSchool.region_id;
              delete newSchool.last_updated;
              delete newSchool.updated_by;
              delete newSchool.school_group_id;
              delete newSchool.category;

              if (newSchool.status === 'Inactive') {
                newSchool.name = `${newSchool.name} (Inactive)`;
              }

              return newSchool;
            });
            delete newItem.schools;
          }
          return newItem;
        })
        .filter((item) => item.children && item.children.length > 0);
    },
  },
};
</script>